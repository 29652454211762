export const theme = {
  colors: {
    backgroundColor: "#000",
    textColor: "#fff",
    orange: "orange",
    blue: "blue",
    red: "red",
    yellow: "#CCC",
    yellow_gold: "#FFF",
    yellow_light: "#FFF",
    amarelo: "#FFCE29",
    black: "black",
    white: "white",
    silver: "silver",
    silver_dark: "#1D1E21",
    background_menu: "#10161b",
  },
};
