import { BrowserRouter, Routes as RoutesDOM, Route } from "react-router-dom";
import { Home, ListChannels } from "../pages/app";
import { Login } from "../pages/auth/Login";
import { AuthRoute } from "./authRoute";
import { PrivateRoute } from "./privateRoute";

export const Routes = () => {
  return (
    <BrowserRouter>
      <RoutesDOM>
        <Route path="*" element={<Home />} />
        <Route
          path="/"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          path="home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />

        <Route
          path="listChannels"
          element={
            <PrivateRoute>
              <ListChannels />
            </PrivateRoute>
          }
        />
      </RoutesDOM>
    </BrowserRouter>
  );
};
