import { styled } from "@mui/material/styles";
import { Button as MaterialButton } from "@mui/material";
import { theme } from "../../styles/theme";

const StyledButton = styled(MaterialButton)(
  ({ fontColor, backgroundColor }) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: backgroundColor ? backgroundColor : theme.colors.yellow,
    borderColor: backgroundColor ? backgroundColor : theme.colors.yellow,
    color: fontColor ? fontColor : theme.colors.black,
    borderRadius: 30,
    fontFamily: "Ubuntu",
    height: 50,
    "&:hover": {
      backgroundColor: backgroundColor
        ? backgroundColor
        : theme.colors.yellow_gold,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: `0 0 0 0.2rem ${
        backgroundColor ? backgroundColor : theme.colors.yellow_gold
      }`,
    },
  })
);

export const Button = ({
  fontColor = false,
  backgroundColor = false,
  ...props
}) => {
  return (
    <StyledButton
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      {...props}
    />
  );
};
