import { useAuth } from "../context/auth";
import { useLocation, Navigate } from "react-router-dom";

export const AuthRoute = ({ children }) => {
  const { token } = useAuth();
  let location = useLocation();
  if (token) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }
  return children;
};
