import React from "react";
import { Box } from "@mui/system";
import { theme } from "../../../../styles/theme";
import LogoMultTv from "../../../../assets/images/logo.png";

const stylesLinkMenu = {
  color: theme.colors.white,
  marginLeft: 20,
  fontSize: 18,
  fontWeight: "400",
  textDecoration: "none",
};

export const LinkMenu = ({ label, href }) => {
  return (
    <a href={href} style={{ ...stylesLinkMenu }}>
      {label}
    </a>
  );
};

export const NavbarLogin = () => {
  return (
    <Box
      sx={{
        widht: "100%",
        height: { xs: "70px", md: "100px" },
        backgroundColor: theme.colors.black,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "20px 20px",
        transition: "all .4s ease-in-out",
      }}
    >
      <Box sx={{ mr: 4 }}>
        <img src={LogoMultTv} alt="logo" style={{ width: 120 }} />
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
        <LinkMenu label="Sobre" />
        <LinkMenu label="TV por assinatura" />
        <LinkMenu label="Streaming" />
        <LinkMenu label="Contato" />
      </Box>
    </Box>
  );
};
