import { Box, Typography } from "@mui/material";
import { dataFormater } from "../../../helpers/date";
import { theme } from "../../../styles/theme";

export const Programacao = ({ num, nome, desc, init, fim, nextInit }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: 2,
        marginTop: 4,
        paddingBottom: 2,
        borderBottom: "1px solid silver",
      }}
    >
      {init ? (
        <Typography
          sx={{
            fontSize: { xs: 14 },
            fontWeight: 300,
            color: theme.colors.yellow_gold,
          }}
        >
          AO VIVO
        </Typography>
      ) : (
        <Typography
          sx={{
            fontSize: { xs: 14 },
            fontWeight: 300,
            color: theme.colors.white,
          }}
        >
          A SEGUIR
        </Typography>
      )}
      <Typography
        sx={{
          fontSize: 28,
          fontWeight: 700,
          color: init ? theme.colors.yellow_gold : theme.colors.white,
        }}
      >
        {nome ?? ""}
      </Typography>
      {init && (
        <Typography
          sx={{ fontSize: 16, fontWeight: 300, color: theme.colors.white }}
        >
          {dataFormater(init)} - {dataFormater(fim)}
        </Typography>
      )}
      {nextInit && (
        <Typography
          sx={{ fontSize: 16, fontWeight: 300, color: theme.colors.white }}
        >
          Inicia: {dataFormater(nextInit)}
        </Typography>
      )}
      <Typography
        sx={{ fontSize: 14, fontWeight: 300, color: theme.colors.white, mt: 2 }}
      >
        {desc}
      </Typography>
    </Box>
  );
};
