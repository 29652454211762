import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Input = styled.input`
  width: 100%;
  padding: 8px 14px;
  background-color: transparent;
  border: none;
  color: ${theme.colors.white};
  height: 50px;
  border-radius: 25px;
`;
