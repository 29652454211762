import React from "react";
import { Grid, Box } from "@mui/material";
import { theme } from "../../../styles/theme";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ListItemChannel } from "../../app/ListChannels/componentes/ListItemChannel";

export const ListChannelsVertical = ({
  channels,
  currentChannel,
  onChangeCurrentChannel,
}) => {
  return (
    <Grid
      item
      xs={0}
      md={4}
      lg={3}
      sx={{
        display: { xs: "none", md: "grid" },
        height: { xs: "calc(100vh - 70px)", md: "calc(100vh - 100px)" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 4,
          height: 60,
        }}
      >
        <ArrowBackIosIcon sx={{ color: theme.colors.white }} />
        <h5
          style={{ color: theme.colors.white, fontWeight: 400, fontSize: 22 }}
        >
          Ao vivo/A Seguir
        </h5>
        <ArrowForwardIosIcon sx={{ color: theme.colors.white }} />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          backgroundColor: theme.colors.background_menu,
        }}
      >
        {channels?.map((channel) => (
          <ListItemChannel
            key={channel.epg_id}
            channel={channel}
            isSelect={channel?.epg_id === currentChannel?.epg_id}
            onClick={() => onChangeCurrentChannel(channel)}
          />
        ))}
      </Box>
    </Grid>
  );
};
