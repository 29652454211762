import React, { useState } from "react";
import { Box } from "@mui/system";
import { theme } from "../../styles/theme";
import LogoMultTv from "../../assets/images/logo.png";
import { LinkRouter } from "./LinkRouter";

import Search from "../../assets/icones/search.png";
import Settings from "../../assets/icones/sett.png";
import Logout from "../../assets/icones/logout.png";

import { FiMenu } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { LinkRouterHorizontal } from "./LinkRouterHorizontal";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";

const styleButton = {
  backgroundColor: "transparent",
  border: "none",
  marginLeft: 12,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
};

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const navigation = useNavigate();
  const { signOut } = useAuth();

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleSignOut = () => {
    signOut();
    navigation("/");
  };
  return (
    <>
      {menuOpen && (
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            opacity: { xs: 1, md: 0 },
            position: "absolute",
            width: 250,
            py: 4,
            backgroundColor: theme.colors.backgroundColor,
            top: "70px",
            left: 0,
            transition: "all 0.4s, ease-in-out",
            zIndex: 99,
          }}
        >
          <LinkRouterHorizontal label="Home" to="/home" />
          <LinkRouterHorizontal label="Ao vivo" to="/live" />
          <LinkRouterHorizontal label="Filmes" to="/films" />
          <LinkRouterHorizontal label="Séries" to="/series" />

          <button style={{ ...styleButton }}>
            <img src={Search} alt="" style={{ height: 60, width: 60 }} />
            <span style={{ color: theme.colors.yellow_gold }}> Pesquisar </span>
          </button>
          <button style={{ ...styleButton }}>
            <img src={Settings} alt="" style={{ height: 60, width: 60 }} />
            <span style={{ color: theme.colors.yellow_gold }}>
              Configurações
            </span>
          </button>
          <button style={{ ...styleButton }} onClick={handleSignOut}>
            <img src={Logout} alt="" style={{ height: 60, width: 60 }} />
            <span style={{ color: theme.colors.yellow_gold }}> Sair </span>
          </button>
        </Box>
      )}
      <Box
        sx={{
          widht: "100%",
          height: { xs: "70px", md: "100px" },
          backgroundColor: theme.colors.black,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px 20px",
          transition: "all .4s ease-in-out",
          px: 5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <button
              onClick={toggleMenu}
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                marginRight: 12,
              }}
            >
              {menuOpen ? (
                <IoMdClose size={40} color={theme.colors.white} />
              ) : (
                <FiMenu size={40} color={theme.colors.white} />
              )}
            </button>
          </Box>

          <img src={LogoMultTv} alt="logo" style={{ width: 120 }} />
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
          <LinkRouter label="Home" to="/home" />
          <LinkRouter label="Ao vivo" to="/live" />
          <LinkRouter label="Filmes" to="/films" />
          <LinkRouter label="Séries" to="/series" />
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
          <button style={{ ...styleButton }}>
            <img src={Search} alt="" style={{ height: 60, width: 60 }} />
          </button>
          <button style={{ ...styleButton }}>
            <img src={Settings} alt="" style={{ height: 60, width: 60 }} />
          </button>
          <button style={{ ...styleButton }}>
            <img
              src={Logout}
              alt=""
              style={{ height: 60, width: 60 }}
              onClick={handleSignOut}
            />
            <span style={{ color: theme.colors.yellow_gold }}> Sair </span>
          </button>
        </Box>
      </Box>
    </>
  );
};
