import { Box, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useAuth } from "../../../context/auth";
import { getChannels } from "../../../services/channel";
import ReactPlayer from "react-player";
import Drawer from "@mui/material/Drawer";

import { DrawerContent } from "./componentes/DrawerContent";
import { Navbar } from "./componentes/Navbar";

import { useNavigate } from "react-router-dom";
import { theme } from "../../../styles/theme";
import { dataFormater } from "../../../helpers/date";

const drawerWidth = 380;

export const ListChannels = (props) => {
  const [channels, setChannels] = useState([]);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { token, signOut } = useAuth();
  const navigate = useNavigate();

  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleSignOut = () => {
    signOut();
    navigate("/");
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };

  const handleChangeCurrentVideo = (newCurrentChannel) => {
    setCurrentChannel(newCurrentChannel);
  };

  useEffect(() => {
    async function getCanais() {
      const response = await getChannels({ token });
      setChannels(response?.data?.canais ?? []);
      setCurrentChannel(response?.data?.canais[0]);
    }
    getCanais();
  }, [token]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Navbar handleDrawerToggle={handleDrawerToggle} />
      <Box
        sx={{ display: "flex", height: "calc(100vh - 70px)", width: "100%" }}
      >
        <Box
          component="nav"
          sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <DrawerContent
              channels={channels}
              currentChannel={currentChannel}
              handleChannelPress={handleChangeCurrentVideo}
            />
          </Drawer>
          <Box
            sx={{
              display: { xs: "none", lg: "block" },
              width: drawerWidth,
            }}
            open
          >
            <DrawerContent
              channels={channels}
              currentChannel={currentChannel}
              handleChannelPress={handleChangeCurrentVideo}
            />
          </Box>
        </Box>
        <Box
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh - 70px",
            overflowY: "auto",
            width: {
              xs: "100%",
              lg: `calc(100% - ${drawerWidth}px)`,
            },
            transition: "all 0.3s ease-in-out",
          }}
        >
          <Grid
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ReactPlayer url={currentChannel?.url} loop playing controls />
            <Box
              component="div"
              sx={{
                display: { xs: "none", sm: "flex" },
                flex: 1,
                backgroundColor: theme.colors.backgroundColor,
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={currentChannel?.logo}
                alt="logo channel"
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "40px",
                  backgroundColor: theme.colors.white,
                  marginBottom: 12,
                }}
              />
              <h1 style={{ color: theme.colors.white }}>
                {currentChannel?.nome}
              </h1>
            </Box>
          </Grid>
          <Grid
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              padding: 4,
            }}
          >
            <Grid sx={{ marginBottom: 4 }}>
              <h1 style={{ marginBottom: 8, color: theme.colors.white }}>
                {dataFormater(currentChannel?.inicio)} -{" "}
                {dataFormater(currentChannel?.fim)} | {currentChannel?.agora}
              </h1>
              <h4
                style={{
                  color: theme.colors.white,
                  opacity: 0.8,
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                {currentChannel?.descnow}
              </h4>
            </Grid>

            <Grid sx={{ marginBottom: 2 }}>
              <h1 style={{ marginBottom: 8, color: theme.colors.white }}>
                Começa - {dataFormater(currentChannel?.fim)} |{" "}
                {currentChannel?.depois}
              </h1>
              <h4
                style={{
                  color: theme.colors.white,
                  opacity: 0.8,
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                {currentChannel?.descnext}
              </h4>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
