import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

@-webkit-keyframes autofill {
    0%,100% {
        color: #fff;
        background: transparent;
    }
}
  
  html {
    --white: #ffffff;
    --black-opacity: #00000050;
    --black: #000000;
    --nero: #242424;
    --light-grey: #D1D1D1;
    --eclipse: #3F3F3F;
    --charcoal:#707070;
    --silver: #C3C3C3;
    --zambezi: #5A5A5A;
    --quick-silver: #999999;
    --whisper: #E9E9E9;
    --red: #F00000;
    --green: #5A8E54;
    --yellow: #ffffff;
    --blue: #3172b7;
    --orange: #F8631C;
    // news colors 
    --background-gallery: #f5f5f5;
    --silver-100: #e0e0e0;
    --silver-200: #bdbdbd;
    --silver-300: #acacac;
    --silver-400: #8d8d8d;
    --silver-700: #3b3b3b;
    --silver-600: #545454;
    --green-dark: #63955A;
    --green-normal: #47934F;
    --yellow-edit: #ffffff;
    --yellow-radio: #ffffff;
    --yellow-gallery: #ffffff;
    --yellow-select: #ffffff;
  }
  /* TODO - CRIAR VARIAVEIS TOAST*/
  /* TOAST */
  /* #ebf8ff */
  /* #3172b7 */
  /* #e6fffa */
  /* #2e656a */
  /* #fddede */
  /* #F00000 */
  * {
    margin:0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;
  }
  body {
    -webkit-font-smoothing: antialiased;
    background-color: var(--eclipse);
    overflow: hidden;

    font-family: 'Ubuntu', sans-serif;
  }
  body, input, button {
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;

  }

  input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff
}

`;
