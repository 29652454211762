import React, { useState, createContext, useContext } from "react";
import { signInService } from "../services/user";
import toast from "react-hot-toast";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(
    localStorage.getItem("@multitv:token") ?? null
  );

  const signIn = async ({ userName, password }) => {
    await signInService({ userName, password })
      .then((response) => {
        const { token: authToken } = response.data;
        if (authToken) {
          localStorage.setItem("@multitv:token", authToken);
          setToken(authToken);

          toast.success("Login bem sucessido");
        }

        if (response.data.status === "erro") {
          toast.error("Login mal sucessido");
        }
      })
      .catch(() => {
        toast.error("Login mal sucessido");
      });
  };

  const signOut = () => {
    localStorage.removeItem("@multitv:token");
    setToken(null);
    // navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
