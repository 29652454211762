import { Box } from "@mui/material";
import { ListItemChannel } from "../ListItemChannel";
import { theme } from "../../../../../styles/theme";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
export const DrawerContent = ({
  channels,
  currentChannel,
  handleChannelPress,
}) => (
  <div>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: 1,
        height: 60,
        backgroundColor: theme.colors.black,
      }}
    >
      <ArrowBackIosIcon sx={{ color: theme.colors.white }} />
      <h2 style={{ color: theme.colors.white }}> Todos </h2>
      <ArrowForwardIosIcon sx={{ color: theme.colors.white }} />
    </Box>
    <Box
      sx={{
        width: "100%",
        height: { xs: "calc(100vh - 60px)", lg: "calc(100vh - 120px)" },
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        backgroundColor: theme.colors.black,
      }}
    >
      {channels?.map((channel) => (
        <ListItemChannel
          key={channel.epg_id}
          channel={channel}
          isSelect={channel?.epg_id === currentChannel?.epg_id}
          onClick={() => handleChannelPress(channel)}
        />
      ))}
    </Box>
  </div>
);
