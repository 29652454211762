import React from "react";
import { Grid } from "@mui/material";

export const ListChannelsHorizontal = () => {
  return (
    <Grid
      item
      xs={12}
      md={0}
      sx={{ display: { xs: "grid", md: "none" } }}
    ></Grid>
  );
};
