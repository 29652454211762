import React from "react";
import { theme } from "../../styles/theme";
import { Link } from "react-router-dom";
import LiveTv from "../../assets/icones/tv.png";
import Films from "../../assets/icones/films.png";
import Series from "../../assets/icones/series.png";
import Home from "../../assets/icones/home.png";

const stylesLinkMenu = {
  color: theme.colors.white,
  marginLeft: 20,
  fontSize: 14,
  fontWeight: "bold",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  marginBottom: 12,
};

export const LinkRouterHorizontal = ({ label, to }) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <div style={{ ...stylesLinkMenu }}>
        {to === "/home" && (
          <img
            src={Home}
            alt=""
            style={{ height: 50, width: 50, marginRight: 20 }}
          />
        )}
        {to === "/live" && (
          <img
            src={LiveTv}
            alt=""
            style={{ height: 50, width: 50, marginRight: 20 }}
          />
        )}
        {to === "/films" && (
          <img
            src={Films}
            alt=""
            style={{ height: 50, width: 50, marginRight: 20 }}
          />
        )}
        {to === "/series" && (
          <img
            src={Series}
            alt=""
            style={{ height: 50, width: 50, marginRight: 20 }}
          />
        )}
        {label}
      </div>
    </Link>
  );
};
