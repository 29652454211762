import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../styles/theme";
import { Navbar } from "../../../components/Navbar";
import { ListChannelsVertical } from "./ListChannelsVertical";
import { ListChannelsHorizontal } from "./ListChannelsHorizontal";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth";
import { getChannels } from "../../../services/channel";
import { getPlayer } from "../../../services/player";
import ImageBackground from "../../../assets/images/background.png";
import ReactPlayer from "react-player";
import ClassL from "../../../assets/class/classific-L.png";
import { dataFormater } from "../../../helpers/date";
import { Programacao } from "./Programacao";

export const Home = (props) => {
  const [channels, setChannels] = useState([]);
  const [currentChannel, setCurrentChannel] = useState(null);

  console.log("current chanel", currentChannel);

  const { token } = useAuth();

  const onChangeCurrentChannel = (newCurrentChannel) => {
    getUrl(newCurrentChannel);
    //setCurrentChannel(newCurrentChannel);

  };

  async function getUrl(newCurrentChannel) {
    const response = await getPlayer({
      url: newCurrentChannel?.url
    });
    setCurrentChannel({ ...newCurrentChannel, url: response?.data?.url });
  }

  useEffect(() => {
    async function getCanais() {
      const response = await getChannels({ token });
      setChannels(response?.data?.canais ?? []);
      setCurrentChannel(response?.data?.canais[0]);
    }
    getCanais();
  }, [token]);

  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${ImageBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "cover",
      }}
    >
      <Navbar />
      <Grid
        container
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <ListChannelsVertical
          channels={channels}
          currentChannel={currentChannel}
          onChangeCurrentChannel={onChangeCurrentChannel}
        />
        <Grid
          item
          xs={0}
          md={8}
          lg={9}
          sx={{
            pl: 4,
            pr: 2,
            py: 2,
            height: "100%",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: theme.colors.white,
              fontWeight: 300,
              opacity: 0.8,
              fontSize: 28,
              textTransform: "uppercase",
              mb: 1,
            }}
          >
            {currentChannel?.nome} AO VIVO
          </Typography>
          <Box
            sx={{
              height: "45%",
              width: "100%",
              display: "flex",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: { xs: "100%", lg: "60%" },
                position: "relative",
              }} // react player
            >
              <ReactPlayer
                url={currentChannel?.url}
                loop
                playing
                controls
                width={"100%"}
                height={"auto"}
                style={{
                  backgroundColor: theme.colors.black,
                }}
              />
            </Box>
            <Box //wrapper info react player
              sx={{
                height: "100%",
                width: { xs: "0%", lg: "40%" },
                display: { xs: "none", lg: "flex" },
                paddingTop: 0,
                pl: 4,
              }}
            >
              <Box sx={{ pr: { lg: "10%" } }}>
                <img src={ClassL} alt="" style={{ width: 50, height: 50 }} />
                <Typography
                  sx={{
                    fontSize: 32,
                    fontWeight: "bold",
                    color: theme.colors.yellow_gold,
                    mt: 2,
                  }}
                >
                  Programa
                </Typography>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 300,
                    color: theme.colors.white,
                    mt: 2,
                  }}
                >
                  {dataFormater(currentChannel?.inicio)} -{" "}
                  {dataFormater(currentChannel?.fim)}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 300,
                    color: theme.colors.white,
                    mt: 2,
                  }}
                >
                  {currentChannel?.descnow}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box //wrapper programacao channel
            sx={{
              height: { xs: "calc(100vh - 68vh)", xl: "calc(100vh - 65vh)" },
              width: "100%",
              overflowY: "scroll",
              backgroundColor: theme.colors.background_menu,
              mt: 2,
              py: 1,
              px: 4,
            }}
          >
            <Programacao
              num={currentChannel?.num}
              nome={currentChannel?.agora}
              desc={currentChannel?.descnow}
              init={currentChannel?.inicio}
              fim={currentChannel?.fim}
            />

            <Programacao
              num={currentChannel?.num}
              nome={currentChannel?.depois}
              desc={currentChannel?.descnext}
              nextInit={currentChannel?.fim}
            />
          </Box>
        </Grid>
        <ListChannelsHorizontal />
      </Grid>
    </Box>
  );
};
