import { AuthProvider } from "./context/auth";
import { GlobalStyle } from "./styles/global";
import { Routes } from "./routes";
import { Toaster } from "react-hot-toast";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { theme as themeColors } from "./styles/theme";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "Ubuntu",
    },
    palette: {
      warning: {
        main: themeColors.colors.yellow_gold,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <GlobalStyle />
        <Toaster />
        <Routes />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
