import * as React from "react";
import { theme } from "../../styles/theme";
import { Input } from "./styles";

export const RoundedInput = ({ ...props }) => {
  return (
    <div
      style={{
        width: "100%",
        border: `2px solid ${theme.colors.yellow_gold}`,
        borderRadius: 50,
        position: "relative",
      }}
    >
      <span
        style={{
          position: "absolute",
          top: -9,
          left: 14,
          color: theme.colors.yellow_gold,
          fontSize: 12,
          fontWeight: "bold",
          backgroundColor: theme.colors.black,
          padding: "0px 4px",
        }}
      >
        {props.label}
      </span>
      <Input type="text" {...props} />
    </div>
  );
};
