import { ListItemButton, Typography, Box, LinearProgress } from "@mui/material";
import { theme } from "../../../../../styles/theme";
import { dataFormater, sizeBar } from "../../../../../helpers/date";

export const ListItemChannel = ({ channel, isSelect, ...props }) => {
  const progress = sizeBar(channel);

  return (
    <ListItemButton
      style={{
        backgroundColor: isSelect ? theme.colors.silver_dark : "transparent",
        marginBottom: 20,
        paddingTop: 10,
        paddingBottom: 10,
        color: theme.colors.white,
        transition: "all 0.3s ease-in-out",
        widht: "100%",
      }}
      {...props}
    >
      <div
        style={{
          width: "24%",
          height: 60,
          backgroundColor: theme.colors.white,
          borderRadius: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={channel.logo}
          alt="channelLogo"
          style={{
            objectFit: "contain",
            widht: "90%",
            height: "90%",
            borderRadius: 30,
          }}
        />
      </div>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 12,
          flex: 1,
        }}
      >
        <Typography style={{ fontSize: 14, fontWeight: 700 }}>
          {channel.nome}
        </Typography>
        {channel?.inicio && (
          <Typography style={{ fontSize: 12, fontWeight: 400 }}>
            {dataFormater(channel?.inicio)} - {dataFormater(channel?.fim)}
          </Typography>
        )}
        <Box sx={{ widht: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            color="warning"
            sx={{
              backgroundColor: "#fff",
              "& .MuiLinearProgress-bar": {
                backgroundColor: theme.colors.amarelo,
              },
            }}
          />
        </Box>
      </Box>
    </ListItemButton>
  );
};
